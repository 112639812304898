import React, {Component, useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Accordion, Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './FAQ.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Col lg={5}>
            <Accordion>
                <Accordion.Item eventKey={boxNumber} className="question-item">
                    <Accordion.Header className="question-box" onClick={toggleQuestion}>
                        <span className="question-title">
                            {props.list.question}
                        </span>
                        {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
                    </Accordion.Header>
                    <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
                            {/* {props.list.answer}  */}
                        {/* <Card.Body> 
                        </Card.Body> */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Col>
    );
}

const FAQ = (props) => {
    const [ list, setList ] = useState([
        {
            question: "Apa itu Landack?", 
            answer: "Landack adalah platform digital penyedia jasa konsultasi hukum dan jasa hukum untuk individu maupun bisnis",
            open: false
        },
        {
            question: "Hukum apa saja yang dicakup oleh Landack?", 
            answer: "Konsultasikan segala permasalahan mu dari berbagai sudut dan kategori hukum, Landack siap!",
            open: false
        },
        {
            question: "Siapakah mitra firma hukum Landack?", 
            answer: `Semua konsultasi sobat dengan Landack akan ditangani oleh konsultan hukum yang didukung oleh Pribadi Randa & Partners Law Firm. Kalau penasaran dengan PRP, bisa klik link website PRP dibawah ini ya: <br /> <a target="_blank" href="https://www.prplawfirm.com">https://www.prplawfirm.com</a>`,
            open: false
        },
        {
            question: "Berapa harga konsultasi di Landack?", 
            answer: "Harga konsultasi dengan ahli hukum Landack mulai dari Rp1.000 / menit",
            open: false
        },
        {
            question: "Bagaimana cara bayar jasanya?", 
            answer: "Tenang saja, sobat bisa menggunakan berbagai metode pembayaran seperti transfer antar bank, <i>virtual account</i>, QRIS, atau melalui Alfamart. Tinggal pilih mana yang sobat suka",
            open: false
        },
        {
            question: "Apakah bisa konsultasi kapanpun?", 
            answer: `Mitra firma hukum Landack bisa dihubungi selama jam operasional ya, yaitu pkl 09:00 - 20:00 WIB. Chat sobat akan ditampung di luar jam itu dan akan diproses sesuai antrian saat sudah kembali di jam itu. Diluar jam operasional tersebut, konsultan hukum Landack yang online akan melayani sobat.`,
            open: false
        },
        {
            question: "Bagaimana cara mulai konsultasi dengan Landack?", 
            answer: `Sobat tinggal download aplikasinya lalu masuk ke halaman utamanya dan klik tombol "Konsultasi di Landack Sekarang" dimana sobat akan langsung dihubungkan dengan mitra firma hukum Landack Atau, sobat dapat klik bagian "Konsultasi Hukum" dimana sobat bisa memilih berkonsultasi dengan mitra firma hukum Landack atau Konsultan Hukum Landack Sobat akan dituntun untuk prosedur pembayaran serta data lain yang dibutuhkan sebelum mulai konsultasi ataupun pengerjaan jasa hukum lainnya. Kalau sudah bayar dan lengkap semua, maka sobat bisa langsung berkonsultasi atau menggunakan jasa hukum lainnya. DIJAMIN TANPA RIBET!
            `,
            open: false
        },
        {
            question: "Bagaimana kalau permasalahan saya tidak terjawab?", 
            answer: "Tenang, Landack bekerja sama dengan Kantor Hukum bersertifikasi untuk memastikan semua permasalahan sobat dapat terjawab dengan baik dan akurat. Kalau permasalahan sobat sangat rumit, kantor hukum rekanan Landack akan memberikan jawabannya hanya dalam 1x24 jam setelah konsultasi selesai!",
            open: false
        },
        {
            question: "Kenapa maskotnya binatang landak?", 
            answer: "Saat musim dingin, binatang landak akan menghargai sobatnya dalam ketidaksempurnaan dan saling menghangatkan satu sama lain meski tajam durinya. Landack menghargai setiap keadaan sobat yang bermacam-macam bentuknya dan menyarankan solusinya dengan cekatan.",
            open: false
        }
    ]);
    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    return (
        <div id="faq" className="faq-box">
            <div className="faq-header">
                <h2 className='faq-title'> FAQ </h2>
            </div>
            <Row className="question-group">
                {
                    list.map( (item, index) => {
                        return(
                            <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                        );
                    } )
                }
            </Row>
        </div>
    );
}

export default FAQ;