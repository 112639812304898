import React, { ReactDOM, useState, useEffect } from 'react';
import { Overlay } from 'react-bootstrap';
import './B2BPackageEnterprise.scss';

import Init from '../../../Init/Init';
import { connect } from 'react-redux';
import ConsultButton from '../../Utilities/ConsultButton/ConsultButton';
import { buildWaLink } from '../../../Util/Util';

const { FontAwesomeIcon, config } = Init;
const { lawyer } = config;

const mapStateToProps = (state) => {
    return {
        legalService: state.legalService.data
    }
}

function B2BPackageEnterprise(props) {
    const [firstColumn, setFirstColumn] = useState({
        title: "Contoh Layanan Landack",
        content: [
            
        ],
        excluding: [
            "Biaya pendaftaran merek",
            "Biaya pendirian badan usaha",
            "Biaya pengurusan ke notaris, pengadilan, kejaksaan, kepolisian, dan eksternal lainnya",
            "Biaya dinas",
            "Biaya pajak"
        ],
        including: [
            "Pertemuan 1x tatap muka per bulan",
            "Weekly update melalui online meeting"
        ],
        documentationSettings: [
            "Pembuatan Surat Resmi",
            "Pendampingan Pembuatan SOP",
            "Pendampingan Pembuatan Perjanjian Kerja",
            "Pendampingan Pengurusan Perizinan",
            "Analisa Atas Masalah Hukum",
            "Dan lainnya!"
        ]
    }); // Legal Services
    const [secondColumn, setSecondColumn] = useState({
        mascot: lawyer, // Landack. Advokat
        text: "Dengan hanya Rp 10.000.000,- / bulan selama 12 bulan, bisnis sobat mendapat safety net yang berkelanjutan, inklusif, berkualitas, dan terjangkau!"
    }); // Mascot
    const [thirdColumn, setThirdColumn] = useState([
        {
            title: "Manfaat bagi Bisnis",
            content: [
                'Mitigasi Keterbatasan Tenaga Hukum Perusahaan',
                'Lindungi Kepentingan Bisnis',
                'Dapatkan Value Lebih dari Uang Sobat',
                'Mitigasi Risiko Hukum dan Meningkatkan Kepatuhan'
            ]
        }
    ]); // Benefits

    const [ showScope, setShowScope ] = useState(false);
    const [ showDocs, setShowDocs ] = useState(false);

    const toggleScope = (toggle) => {
        console.log("Toggle scope: " + toggle);
        setShowScope(toggle);
    };
    
    const toggleDocs = (toggle) => {
        setShowDocs(toggle);
    }

    useEffect(() => {
        setFirstColumn({
            ...firstColumn,
            content: props.legalService.map( (item, index) => {
                return {
                    icon: `${ process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE }${ item.app_icon_path }`,
                    code: item.service_code,
                    name: item.service_name,
                    isBilingual: item.service_name.indexOf('Bilingual') > -1
                }
            } )
        });
    }, [props.legalService]);

    return (
        <div id="enterprise" className='b2b-package-enterprise'>
            <h2 className="enterprise-title">
                Landack Full Service
            </h2>
            {/* 
                1. First Column - Legal Service
                2. Second Column - Mascot
                3. Third Column - Benefits
            */}
            <div className="enterprise-content">
                <div className="first-column">
                    <div className="legal-service-list-title" id="legal-service-title">
                        { firstColumn.title }: 
                        <button
                            className='hover-icon'
                            onMouseEnter={ () => toggleScope(true) }
                            onMouseLeave={ () => toggleScope(false) }
                        >
                            <FontAwesomeIcon 
                                icon={ ['fas', 'info-circle'] } 
                            />
                        </button>
                        <Overlay 
                            show={ showScope }
                            onHide={ () => toggleScope(false) }
                            container={ document.getElementById('legal-service-title') }
                            target={ document.getElementsByClassName('hover-icon') }
                            placement="right"
                        >
                            <div className="exclude-include">
                                <div className="excluding-items">
                                    Tidak Termasuk: 
                                    <ol>
                                        { firstColumn.excluding.map( (item, index) => {
                                            return (
                                                <li key={index}> { item } </li>
                                            )
                                        }) }
                                    </ol>
                                </div>
                                <div className="including-items">
                                    Termasuk: 
                                    <ol>
                                        { firstColumn.including.map( (item, index) => {
                                            return (
                                                <li key={index}> { item } </li>
                                            )
                                        }) }
                                    </ol>
                                </div>
                            </div>
                        </Overlay>
                    </div>
                    <ul className="legal-service-list">
                        {
                            firstColumn.content.map((item, index) => {
                                if( ["MRK", "LDD"].includes(item.code) ) return "";

                                return !item.isBilingual ? (
                                    <li className='legal-service-item' key={index}> 
                                        <img src={ item.icon } />
                                        <span>
                                            { item.name } 
                                        </span>
                                    </li>
                                ) : ''
                            })
                        }
                        <li className='legal-service-item' id="document-settings"> 
                            <img src={ `${ process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE }${ "APP-KH.png" }` } />
                            Dokumentasi & Pengaturan 
                            <button
                                className='hover-icon'
                                onMouseEnter={ () => toggleDocs(true) }
                                onMouseLeave={ () => toggleDocs(false) }
                            >
                                <FontAwesomeIcon 
                                    icon={ ['fas', 'info-circle'] } 
                                />
                            </button>
                            <Overlay 
                                show={ showDocs }
                                onHide={ () => toggleDocs(false) }
                                container={ document.getElementById('document-settings') }
                                target={ document.getElementsByClassName('hover-icon') }
                                placement="right"
                            >
                                <div className="document-settings">
                                    <div className="excluding-items">
                                        <ol>
                                            { firstColumn.documentationSettings.map( (item, index) => {
                                                return (
                                                    <li key={index}> { item } </li>
                                                )
                                            }) }
                                        </ol>
                                    </div>
                                </div>
                            </Overlay>
                        </li>
                    </ul>
                </div>
                <div className="second-column">
                    <div className="enterprise-mascot">
                        <img src={ secondColumn.mascot } />
                    </div>

                    <div className="enterprise-cta">
                        {/* <a href="" className="btn-order-enterprise">
                            Hubungi Kami
                        </a> */}
                        <ConsultButton customClass="btn-order-enterprise" text={ "Hubungi Kami" } href={ buildWaLink("mau berlangganan", "Paket Enterprise") } />
                    </div>
                </div>
                <div className="third-column">
                    <div className="benefit-section">
                        <div className="benefit-title">
                            { thirdColumn[0].title }:
                        </div>
                        <ol className="benefit-list">
                            {
                                thirdColumn[0].content.map( (item, index) => {
                                    return (
                                        <li key={index}>{ item }</li>
                                    )
                                } )
                            }
                        </ol>
                    </div>
                    <div className="enterprise-description">
                        { secondColumn.text }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(B2BPackageEnterprise);