import React from 'react';
import Init from '../../../Init/Init';

import './Background.scss';

const Background = () => {
    const { config } = Init;
    const { lowLiterationIcon, priceClarityIcon, confused } = config;

    return (
        <div id="background" className='background'>
            <div className="background-fixed-content">
                {/* 
                    Title
                    Mascot
                */}
                <h2 className="background-fixed-title">
                    Landack berangkat dari keprihatinan atas:
                </h2>
                <img src={ confused } alt="" className="background-fixed-mascot" />
            </div>
            <div className="background-box" id="box-one">
                {/* 
                   Icon
                   Text 
                */}
                <img className='background-box-icon' src={ lowLiterationIcon } alt="" />
                <div className="background-box-text">
                    Rendahnya Kesadaran Hukum 
                    <span className='changable-text'></span>
                </div>
            </div>
            <div className="background-box" id="box-two">
                <img className='background-box-icon' src={ priceClarityIcon } alt="" />
                <div className="background-box-text">
                    Kurangnya <span className='changable-text'></span> Layanan Hukum
                </div>
            </div>
        </div>
    );
};

export default Background;