import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Init from '../../Init/Init';
import articlesData from '../../Data/Article';
import ArticleCarousel from '../../Component/PagesComponent/ArticleCarousel/ArticleCarousel';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { objectIsEmpty, copyLink } from '../../Util/Util';

import './ArticleDetailPage.scss';
import { Toast } from 'react-bootstrap';

import useAnalyticsEventTracker from '../../GoogleAnalytics/useAnalyticsEventTracker';

import { connect } from 'react-redux';
import articleActions from '../../Redux/Article/article-action';
import metatagActions from '../../Redux/MetaTags/metatag-action';

const { loadArticle, loadArticleDetail } = articleActions;
const { saveMetatag } = metatagActions;

const mapStateToProps = (state) => {
    return {
        article: state.article.data,
        articleIsLoaded: state.article.amount > 0,
        detail: state.article.detail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadArticle: (startId, limit = 10, refresh = true) => dispatch(loadArticle(startId, limit, '', refresh)),
        loadDetail: (id) => dispatch(loadArticleDetail(id)),

        saveMetatag: ( title, description, keywords ) => dispatch(saveMetatag(title, description, keywords))
    };
}

const ArticleDetailPage = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const { FontAwesomeIcon } = Init;

    const [isLoading, setIsLoading] = useState(true);
    const [article, setArticle] = useState({id: 0});
    const [otherArticles, setOtherArticles] = useState([]);
    const [articleAmount, setArticleAmount] = useState(0);
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    const eventTracker = useAnalyticsEventTracker('Article Detail');

    const copyLinkToClipboard = async () => {
        const result = await copyLink();

        eventTracker('Click Share Button', 'Copy Article Link');

        setToastContent({
            message: result.message,
            show: result.errorCode === 0
        });
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    useEffect( () => {
        window.scrollTo({
            top: 0
        });

        if ( !props.articleIsLoaded ) {
            props.loadArticle(null, null);
        }
    }, []);

    useEffect( () => {
        if( props.articleIsLoaded ) {
            console.log(props.article);
            
            let articleSlug = params.title;
            let articleId = 0;
    
            for(let index in props.article) {
                let item = props.article[index];
    
                if( item.article_slug === articleSlug ) {
                    articleId = item.id;
                    break;
                }
            }
    
            props.loadDetail(articleId);
        }
    }, [params.title, props.articleIsLoaded]);

    useEffect( () => {
        if( props.detail.hasOwnProperty('id') ) {
            // Workaround to wrap li in List
            props.detail.article_content = props.detail.article_content.replace(/white-space: pre;/g, "");
        }
        setArticle(props.detail);
    }, [props.detail]);

    useEffect( () => {
        props.loadArticle(article.id, article.id > 0 ? 10 : null);

        props.saveMetatag(
            `${ article.article_title }`,
            `${ article.short_description }`,
            `${ article.meta_keywords }`
        )
    }, [article]);

    useEffect( () => {
        setOtherArticles(Object.values(props.article));
    }, [props.article]);

    useEffect( () => {
        setArticleAmount(otherArticles.length);
        // setIsLoading(false);
    }, [otherArticles]);

    useEffect( () => {
        setIsLoading(false);
    }, [articleAmount]);

    return (
        !isLoading ? (
            <div className='article-detail-page'>
                <Toast 
                    className='copy-toast'
                    show={toastContent.show}
                    // autohide?: boolean;
                    // animation?: boolean;
                    // delay?: number;
                    // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                    // show?: boolean;
                    // transition?: TransitionComponent;
                    // bg={''}
                >
                    <Toast.Body className='copy-toast-body'>
                        <div className="message">
                            { toastContent.message }
                        </div>
                        <button className='btn-close' onClick={closeToast}>
                        </button>
                    </Toast.Body>
                </Toast>
                {/* 
                    Extras:
                    Toast

                    Big Parts:
                    1. Article Image
                    2. Article Content
                    3. Other articles
                */}
                <div className="article-data">
                    <div className="article-header">
                        <h1 className='content-title'>
                            {article.article_title}
                        </h1>
                        <span className="content-publisher">{ article.published_by }</span> - <span className='content-publish-time'>{ article.published_time }</span>
                    </div>
                    <div className="article-image">
                        <img src={article.article_image} alt="" />
                        {
                            article.hasOwnProperty('media_source') && article.media_source !== '' ? (
                                <div className='media-source'>Sumber foto: {article.media_source}</div>
                            ) : ''
                        }
                    </div>
                    <div className="article-content">
                        <div className='content-text' dangerouslySetInnerHTML={{__html: article.article_content}}></div>
                        <div className="content-btn-share-container">
                            <button className='btn-share' onClick={() => copyLinkToClipboard()}>
                                <FontAwesomeIcon icon={['fas', 'share']} /> Bagikan
                            </button>
                        </div>
                    </div>
                </div>
                {
                    articleAmount ? (
                            <div className="other-articles">
                                <div className="other-articles-header">
                                    <h3>
                                        Artikel Lainnya
                                    </h3>
                                </div>
                                {
                                    otherArticles.map((v, k) => {
                                        return (
                                            <div key={k} className="other-article-item" onClick={() => navigate(`/article/${v.article_slug}`, {
                                                replace: false
                                            })}>
                                                <div className="article-thumbnail">
                                                    <img src={v.article_image} alt="" />
                                                </div>
                                                <div className="article-content">
                                                    <h5 className='content-title'>
                                                        { v.article_title }
                                                    </h5>
                                                    <span className="content-publisher">{ v.published_by }</span> - <span className='content-publish-time'>{ v.published_time }</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    ) : (
                        ''
                    )
                }   
             </div>    
        ) : (
            <div className="article-detail-page">
                <LoadingPage />
            </div>
        )
    );
}

ArticleDetailPage.defaultProps = {
    article: {
        data: {},
        amount: 0,
        detail: {}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailPage);