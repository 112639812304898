import React, { useEffect, useState } from 'react';

import Init from '../../../Init/Init';
import { buildWaLink, legalServiceLink, numberFormatting } from '../../../Util/Util';

import './LegalService.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';

const { config } = Init;

const mapStateToProps = (state) => {
    return {
        legalService: state.legalService.data
    };
}

const LegalServiceList = (props) => {
    const { prices } = props;
    
    const gaEventCategory = 'Layanan Hukum';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);
    
    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "legal-service-item-container";
    carouselConfig.itemClass = "legal-service-item-holder";
    carouselConfig.showDots = true;
    carouselConfig.infinite = false;
    carouselConfig.dotListClass = 'legal-service-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1550, min: 1200 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        // 'desktop',
        // 'tablet',
        // 'mobile'
    ];

    return (
        <Carousel
                // arrows={false}
                // renderButtonGroupOutside={true}
                // customButtonGroup={<ButtonGroup />}
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}
            >
                {
                    prices.map( (v, k) => {
                        return (
                            <div key={k} className="legal-service-item">
                                <img src={v.icon} alt="Icon Layanan" className='legal-service-item-icon' />
                                <h3 className="legal-service-item-title">
                                    { v.name }
                                </h3>
                                {
                                    v.hasOwnProperty('description') ? (
                                        <p className="legal-service-item-desc">
                                            { v.description }
                                        </p>
                                    ) : ''
                                }
                                {
                                    v.hasOwnProperty('descriptionList') ? (
                                        <ul className='legal-service-item-desc-list'>
                                            {
                                                v.descriptionList.map( (item, index) => {
                                                    return (
                                                        <li key={index} className="legal-service-item-desc">
                                                            { item }
                                                        </li>
                                                    );
                                                } )
                                            }
                                        </ul>
                                    ) : ''
                                }
                                {
                                    v.hasOwnProperty('price') ? (
                                        <span className='legal-service-item-price'>
                                            { v.price }
                                        </span>
                                    ) : ''
                                }
            
                                <a 
                                    className="btn-legal-service" 
                                    rel="noreferrer"
                                    target="_blank" 
                                    href={ v.buttonLink } 
                                    onClick={ () => gaEventTracker('Click Order Now Button', v.actionLabel)}
                                    onMouseEnter={ () => gaEventTracker('Hover Order Now Button', v.actionLabel)}
                                >
                                    Pesan Sekarang
                                </a>
                            </div>
                        );
                    } )
                }
        </Carousel>
    );
}

const LegalServiceChart = (props) => {
    const { prices } = props;

    const { androidAndIos, iconFolder } = config;
    
    const gaEventCategory = 'Layanan Hukum';
    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    return (
        <div className='legal-service-chart-container'>
            <div className="legal-service-chart">
                <table className="legal-service-chart-table">
                    <tbody>
                        {
                            prices.map( (item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        onMouseOver={ () => gaEventTracker('Hover Service', item.actionLabel) }
                                        className={item.hasOwnProperty('isNew') && item.isNew ? "new-product" : ""}
                                    >
                                        <td> <img src={ item.smallIcon } alt={`Legal Service Icon ${index}`} /> </td>
                                        <td width="50%" className='service-name'> { item.name } { item.hasOwnProperty('isNew') && item.isNew ? (
                                            <img className="new-product-icon" src={iconFolder + "new-product-icon.svg"} />
                                        ) : '' }  </td>
                                        <td> { item.hasOwnProperty('tentativePrice') && item.tentativePrice ? 'Mulai dari' : '' } { item.price }</td>
                                    </tr>
                                )
                            } )
                        }
                    </tbody>
                </table>
            </div>
            <div className="legal-service-download-container">
                <div className="legal-service-mascot">
                    <img src={androidAndIos} alt={ `Android & iOS Icons` } />
                </div>
                <h3 className="legal-service-caption">
                    Download Aplikasi Landack!
                </h3>
                <div className="legal-service-btn-container">
                    <a rel="noopener" target="__blank" href={ config.googlePlayLink } className="btn-download btn-download-android" onClick={ () => gaEventTracker('Download App', "Google Play") }>
                        <img src={ config.googlePlayIcon } alt="Google Play Icon" /> Google Play
                    </a>
                    <a rel="noopener" target="__blank" href={ config.appStoreLink } className="btn-download btn-download-ios" onClick={ () => gaEventTracker('Download App', "Apple App Store") }>
                        <img src={ config.appStoreIcon } alt="App Store Icon" /> App Store
                    </a>
                </div>
            </div>
        </div>
    );
}

const LegalService = (props) => {
    const [ legalService, setLegalService ] = useState([]);
    const legalServiceNotes = [
        '*Seluruh layanan Landack merupakan hasil kerja sama Landack dengan Kantor Hukum bersertifikasi.',
        '*Harga tertera pada seluruh layanan yang berkaitan dengan dokumen hanya untuk Bahasa Indonesia saja.',
        '*Untuk dokumen dengan bahasa selain Indonesia akan dikenakan biaya ekstra.'
    ];

    useEffect( () => {
        const response = props.legalService;

        if( response.length > 0 ) {
            let data = [];

            for(let index in response) {
                let item = response[index];

                data.push({
                    name: item.service_name,
                    icon: `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${item.icon_path}`,
                    smallIcon: `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${item.app_icon_path}`,
                    description: `${item.description}`,
                    price: `${numberFormatting(item.price)} / ${ (item.portion > 1 ? item.portion + ' ' : '') + item.unit }`,
                    buttonLink: legalServiceLink(item.service_code),
                    buttonWa: buildWaLink('mau melakukan', `"${item.service_name}"`),
                    actionLabel: item.service_name,
                    isNew: item.is_new,
                    tentativePrice: item.tentative_price
                });
            }
            
            setLegalService(data);
        }
        
    }, [props.legalService]);

    return (
        <div id="individual-pricing" className="legal-service">
            <h2 className='legal-service-title'>
                Butuh Landack untuk?
            </h2>
            {
                legalService.length > 0 ? (
                    // <LegalServiceList prices={legalService} />
                    <LegalServiceChart prices={legalService} />
                ) : (
                    <div className="legal-service-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
            <div className="legal-service-notes">
                {
                    legalServiceNotes.map((v, k) => {
                        return (
                            <span key={k} className="legal-service-notes-item">
                                { v }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    );
}



export default connect(mapStateToProps)(LegalService);