import React, { useEffect, useState } from 'react';
import './B2BPackageHeader.scss';

import Init from '../../../Init/Init';
const { sectionAssets } = Init.config;

function B2BPackageHeader(props) {
    const [ isMobileScreen, setIsMobileScreen ] = useState(false);

    const header = {
        title: `Layanan Hukum untuk melindungi bisnis sobat`,
        description: `Landack adalah pelopor <em class='highlighted'>Legal Service Aggregator</em> yang menghadirkan jasa konsultasi dan layanan hukum yang reliabel dan terjangkau untuk perusahaan maupun perorangan.`
    };

    useEffect(() => {
        setIsMobileScreen(window.innerWidth <= 576);
    }, []);

    return (
        <div className="b2b-package-header">
            {/* 
                Header
                1. Mascot
                2. Content
            */}
            <div className="b2b-package-title-container">            
                <h1 className='header-content-title' dangerouslySetInnerHTML={{__html: header.title}}>
                </h1>
            </div>
            <div className="b2b-package-header-content">

                <div className="package-content-first-row">
                    <div className="b2b-package-header-asset">
                        <img src={ sectionAssets.b2bPackage.subMain } alt="" />
                    </div>

                    <div className='header-content-description'> 
                        <span dangerouslySetInnerHTML={{__html: header.description}}>
                        </span>
                    </div>
                </div>

                <div className="package-content-second-row">
                    <div className="header-content-cta-container">
                        <button className='header-content-cta-button deposit' onClick={ () => props.onChoose(0) }> Deposit </button>
                        <button className='header-content-cta-button full-service' onClick={ () => props.onChoose(1) }> Full Service </button>
                        <button className='header-content-cta-button legal-boost' onClick={ () => props.onChoose(2) }> Legal Boost </button>
                    </div>
                </div>
                
                {/* <ConsultButton customClass="header-content-cta-button" text={ footer.ctaButton.text } href={ footer.ctaButton.url } /> */}
            </div>
        </div>
    );
}

export default B2BPackageHeader;