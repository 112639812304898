import React, { Fragment, useState, useEffect } from 'react';
import './B2BPackageLegalBoost.scss';

import Init from '../../../Init/Init';
import ConsultButton from '../../Utilities/ConsultButton/ConsultButton';
import { buildWaLink, numberFormatting } from '../../../Util/Util';

const { salute, excited } = Init.config;

function B2BPackageLegalBoost(props) {
    const descriptions = {
        mascot: salute,
        text: `
            Legalitas bisnis sobat seperti kapal pecah? 
            <br />
            Bereskan bareng Landack!
        `
    };

    useEffect( () => {
        const locationHash = window.location.hash;

        if( locationHash ) {
            document.querySelector(`${locationHash}`).scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const pricing = {
        rocket: '',
        mascot: excited,
        title: 'Legal Boost',
        price: 15200000,
        expiryTime: '2 bulan',
        description: [
            {
                main: "Pembuatan 1 template Perjanjian Kerahasiaan",
            },
            {
                main: "Pembuatan 1 template Perjanjian kerja untuk karyawan (PKWT / PKWTT)",
            },
            {
                main: "Pembuatan 3 perjanjian / SOP / Privacy policy / Terms and Conditions sesuai kebutuhan",
            },
            {
                main: "Konsultasi sepuasnya selama 2 bulan",
            },
            {
                main: "Review dokumen 50 halaman selama 2 bulan",
            },
            {
                main: "Pembuatan company profile website 1 pager dalam waktu 2 bulan, sudah termasuk:",
                extra: [
                    "Domain website .com dan .id selama 1 tahun",
                    "Hosting website selama 1 tahun"
                ]
            },
            {
                main: "EXTRA:",
                extra: [
                    "Pengecekan dan pendaftaran 1 kelas Merek; atau",
                    "Review dokumen 50 halaman; atau",
                    "Pembuatan 1 perjanjian / SOP / Privacy policy / Terms and Conditions",
                ]
            },
        ]
    }

    return (
        <div id="legal-boost" className='b2b-package-legal-boost'>
            <h2 className="legal-boost-title">
                Landack Legal Boost
            </h2>
            <div className="legal-boost-content">
                <div className="legal-boost-description">
                    <div className="description-mascot">
                        <img src={ descriptions.mascot } alt="" />
                    </div>
                    <div className="description-text" dangerouslySetInnerHTML={{__html: descriptions.text}}>
                        
                    </div>
                </div>
                <div className="legal-boost-pricing">
                    <div className="pricing-card">
                        <div className="pricing-card-rocket">
                            <img src={ pricing.rocket } />
                        </div>
                        {/* <div className="pricing-card-mascot">
                            <img src={ pricing.mascot } />
                        </div> */}
                        <h3 className="pricing-card-title">
                            { pricing.title }
                        </h3>
                        <div className="pricing-card-price">
                            { numberFormatting(pricing.price) }
                        </div>
                        <div className="pricing-card-cta-button-container">                
                            <ConsultButton customClass={"cta-button"} text={ "Hubungi Kami" } href={ buildWaLink("membutuhkan", "Legal Boost") } />
                        </div>
                        <div className="pricing-card-expiry-time">
                            Berlaku untuk { pricing.expiryTime }
                        </div>
                        <ol className='pricing-card-description'>
                            {
                                pricing.description.map((desc, index) => {
                                    return (
                                        <Fragment key={ index }>
                                            <li className="description-item">
                                                { desc.main }
                                            </li>
                                            {
                                                desc.hasOwnProperty("extra") ? (
                                                    <ol className='description-item-list'>
                                                        {
                                                            desc.extra.map( (item, key) => {
                                                                return (
                                                                    <li key={key} className='item-list-item'>
                                                                        { item }
                                                                    </li>
                                                                )
                                                            } )
                                                        }
                                                    </ol>
                                                ) : ''
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default B2BPackageLegalBoost;