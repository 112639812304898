import React, { Fragment, useState, useEffect } from 'react';
import './B2BPackageDeposit.scss';

import Init from '../../../Init/Init';
import ConsultButton from '../../Utilities/ConsultButton/ConsultButton';
import { buildWaLink, numberFormatting } from '../../../Util/Util';

const { salute, excited, accountant } = Init.config;

function B2BPackageDeposit(props) {
    const descriptions = {
        mascot: accountant,
        text: `
            Masa depan ada di tangan sobat. 
            <br />
            Simpan di Landack sekarang, pakai nanti!
        `
    };

    useEffect( () => {
        const locationHash = window.location.hash;

        if( locationHash ) {
            document.querySelector(`${locationHash}`).scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const pricing = [
        [
            "Layanan",
            "Konsultasi Hukum",
            "Review Dokumen",
            // "Pembuatan Perjanjian / SOP",
            "Pembuatan Perjanjian",
            "Pembuatan Dokumen Hukum"
        ],
        [
            "Tanpa Deposit",
            "Rp15.000 / 15 menit",
            "Rp105.000 / halaman",
            "Rp3.000.000 / dokumen",
            "Rp300.000 / dokumen",
        ],
        [
            "Deposit Minimum <br /> <span class='highlighted'>Rp500.000</span>",
            "GRATIS 1 Bulan",
            "Rp52.500 / halaman",
            "Rp1.500.000 / dokumen",
            "Rp150.000 / dokumen",
        ],
    ];

    return (
        <div id="deposit" className='b2b-package-deposit'>
            <h2 className="deposit-title">
                Landack Deposit
            </h2>
            <div className="deposit-content">
                <div className="deposit-pricing">
                    {/* 
                        1. "Table Header"
                        2. "Table Description"
                            Service - Price
                    */}
                    {
                        pricing.map( (cell, no) => {
                            return (
                                <div key={ no } className="deposit-column">
                                    {
                                        cell.map( (item, index) => {
                                            return (
                                                <div className="deposit-column-item" dangerouslySetInnerHTML={{__html: item}}>

                                                </div>
                                            );
                                        } )
                                    }
                                </div>
                            )
                        } )
                    }
                </div>
                <div className="deposit-description">
                    <div className="description-text" dangerouslySetInnerHTML={{__html: descriptions.text}}>
                        
                    </div>
                    <div className="description-mascot">
                        <img src={ descriptions.mascot } alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default B2BPackageDeposit;