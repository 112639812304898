import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './Collaborator.scss';

const collaborator = Init.config.sectionAssets.collaborator;

const Collaborator = (props) => {
    const [ partners, setPartners ] = useState([]);

    useEffect( () => {
        setPartners([
            {
                logoPath: `${collaborator.unsri}`,
                name: "Universitas Sriwijaya"
            },
            {
                logoPath: `${collaborator.unsrifh}`,
                name: "BEM FH Universitas Sriwijaya"
            },
            {
                logoPath: `${collaborator.binus}`,
                name: "Binus University"
            },
            {
                logoPath: `${collaborator.binusbuslaw}`,
                name: "Himpunan Mahasiswa Business Law Binus University"
            },
            {
                logoPath: `${collaborator.iccn}`,
                name: "ICCN"
            },
            {
                logoPath: `${collaborator.unnas}`,
                name: "Universitas Nasional"
            },
            {
                logoPath: `${collaborator.himakumunnas}`,
                name: "Himakum Universitas Nasional"
            },
            {
                logoPath: `${collaborator.uin}`,
                name: "Universitas Islam Negeri Walisongo"
            },
            {
                logoPath: `${collaborator.himakumuin}`,
                name: "Himakum Universitas Islam Negeri Walisongo"
            },
            {
                logoPath: `${collaborator.kalbis}`,
                name: "Kalbis Student Development & Alumni Center"
            },
            {
                logoPath: `${collaborator.ksp}`,
                name: 'Komunitas Satu Persen'
            },
            {
                logoPath: `${collaborator.jakmall}`,
                name: `Jakmall`
            },
            {
                logoPath: `${collaborator.studiokecil}`,
                name: `Studio Kecil`
            },
            {
                logoPath: `${collaborator.umm}`,
                name: `Universitas Muhammadiyah Malang`,
            },
            {
                logoPath: `${collaborator.ummfh}`,
                name: `BEM FH Universitas Muhammadiyah Malang`,
            },
            {
                logoPath: `${collaborator.uskbemfh}`,
                name: `BEM FH Universitas Syiah Kuala`,
            },
            {
                logoPath: `${collaborator.uskfh}`,
                name: `FH Universitas Syiah Kuala`,
            },
            {
                logoPath: `${collaborator.ukdc}`,
                name: `Universitas Katolik Darma Cendika`
            },
            {
                logoPath: `${collaborator.ukdcfh}`,
                name: `BEM FH Universitas Katolik Darma Cendika`
            },
            {
                logoPath: `${collaborator.ukdckabinetfh}`,
                name: `Kabinet BEM FH Universitas Katolik Darma Cendika`
            },
        ]);
    }, []);

    return (
        <div className='collaborator-section' id="collaborated-with">
            {/* 
                Title
                List of logos (LIKE PRP)
            */}
            <div className="collab-header">
                <h2 className='collab-title'> Partner Kolaborasi </h2>
            </div>
            <Row className="collab-partners">
                {
                    partners.map( (item, index) => {
                        return (
                            <Col md={2} sm={3} xs={6} className="partner-item">
                                <div className="partner-logo">
                                    <img src={ item.logoPath } />
                                </div>
                                <div className="partner-name">
                                    {item.name}
                                </div>
                            </Col>
                        )
                    } )
                }
            </Row>
        </div>
    );
}

export default Collaborator;