import React, {Component, useState} from 'react';
import './B2BPackagePage.scss';

import B2BPackageHeader from '../../Component/PagesComponent/B2BPackageHeader/B2BPackageHeader';
import B2BPackageBenefit from '../../Component/PagesComponent/B2BPackageBenefit/B2BPackageBenefit';
import B2BPackageFooter from '../../Component/PagesComponent/B2BPackageFooter/B2BPackageFooter';
import B2BPackageEnterprise from '../../Component/PagesComponent/B2BPackageEnterprise/B2BPackageEnterprise';
import B2BPackageLegalBoost from '../../Component/PagesComponent/B2BPackageLegalBoost/B2BPackageLegalBoost';
import Membership from '../../Component/PagesComponent/Membership/Membership';
import B2BPackageDeposit from '../../Component/PagesComponent/B2BPackageDeposit/B2BPackageDeposit';


const B2BPackagePage = () => {
    const [ packageIndex, setPackageIndex ] = useState(0);

    const packageList = [
        <B2BPackageDeposit />,
        <B2BPackageEnterprise />,
        <B2BPackageLegalBoost />,
    ];

    const choosePackage = (index) => {
        setPackageIndex(index);
    };

    return (
        <div className="b2b-package-page">
            {/* 
                B2B Package Page
                1. Header Banner
                2. Benefits
                3. Harga (Mulai dari ...)
            */}
            
            <B2BPackageHeader onChoose={(index) => choosePackage(index)} />
            
            { packageIndex === 0 ? (
                <B2BPackageDeposit />
            ) : '' }
            { packageIndex === 1 ? (
                <B2BPackageEnterprise />
            ) : '' }
            { packageIndex === 2 ? (
                <B2BPackageLegalBoost />
            ) : '' }

            {/* <Membership /> */}

            {/* <B2BPackageBenefit /> */}
            {/* <B2BPackageFooter /> */}

        </div>
    );
}

export default B2BPackagePage;