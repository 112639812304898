import React from 'react';
import Init from '../../../Init/Init';
import './B2BPackage.scss';

const { sectionAssets } = Init.config;

const B2BPackage = (props) => {
    return (
        <div className='b2b-package'>
            {/* 
                2 parts:
                1. Title
                2. Button to redirect to another page
            */}
            <div className="b2b-package-assets">
                <img src={sectionAssets.b2bPackage.main} alt="" />
            </div>
            <div className="b2b-package-header">
                <h2>
                    Cek paket dari Landack untuk bisnis sobat yang ingin naik kelas!
                </h2>
            </div>
            <div className="b2b-package-button-container">
                <a href={ '/landack-bisnis' } className='btn-b2b-package' target="_blank">
                    Cek disini!
                </a>
            </div>
        </div>
    );
}

export default B2BPackage;