import React, {Component} from 'react';
import './ServicePage.scss';

import Init from '../../Init/Init';

import Header from '../../Component/PagesComponent/Header/Header';
// import Identity from '../../Component/PagesComponent/Identity/Identity';
// import ComingSoon from '../../Component/PagesComponent/ComingSoon/ComingSoon';
import Advantages from '../../Component/PagesComponent/Advantages/Advantages';
import FAQ from '../../Component/PagesComponent/FAQ/FAQ';
import PricingInfo from '../../Data/Pricing';
import Articles from '../../Component/PagesComponent/Articles/Articles';
import BusinessEntity from '../../Component/PagesComponent/BusinessEntity/BusinessEntity';
import TestimonySection from '../../Component/PagesComponent/Testimony/Testimony';

import ReactGA from 'react-ga4';
import LegalService from '../../Component/PagesComponent/LegalService/LegalService';
import CorporatePackage from '../../Component/PagesComponent/CorporatePackage/CorporatePackage';
import B2BPackage from '../../Component/PagesComponent/B2BPackage/B2BPackage';
import Membership from '../../Component/PagesComponent/Membership/Membership';
import StarterKit from '../../Component/PagesComponent/StarterKit/StarterKit';
import Collaborator from '../../Component/PagesComponent/Collaborator/Collaborator';
import Client from '../../Component/PagesComponent/Client/Client';
import Background from '../../Component/PagesComponent/Background/Background';

class ServicePage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }

    render() {
        return (
            <div className="service-page">
                <LegalService />
                <div id="b2b-section" className="b2b-section">
                    <BusinessEntity />
                    <B2BPackage />
                </div>
            </div>
        );
    }
}

export default ServicePage;