import React from 'react';
import { buildWaLink } from '../../../Util/Util';
import ConsultButton from '../../../Component/Utilities/ConsultButton/ConsultButton';

import Init from '../../../Init/Init';
import './B2BPackageFooter.scss';

const { sectionAssets } = Init.config;

function B2BPackageFooter(props) {
    const footer = {
        title: `Dapatkan manfaat bisnis mulai dari <span class='highlighted'>Rp 10.000.000 / bulan</span> selama 12 bulan!`,
        ctaButton: {
            url: buildWaLink('', '', 'Halo Landack, saya mau tertarik dengan paket B2B yang ditawarkan. Bisa jelaskan lebih lanjut mengenai hal ini?'),
            text: 'Hubungi Kami'
        }
    };

    return (
        <div className="b2b-package-footer">
            <div className="b2b-package-footer-content">
                <h1 className='footer-content-title' dangerouslySetInnerHTML={{__html: footer.title}}>
                </h1>
                <ConsultButton customClass="footer-content-cta-button" text={ footer.ctaButton.text } href={ footer.ctaButton.url } />
            </div>
            <div className="b2b-package-footer-asset">
                <img src={ sectionAssets.corporatePricing.gold } alt="" />
            </div>
        </div>
    );
}

export default B2BPackageFooter;