import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { isMobile } from 'react-device-detect';

var Init = {};

// Constants
Init.constants = {
    serviceType: {
        legalService: 1,
        package: 2,
        businessEntity: 3
    },
    paymentService: {
        purwantara: 1,
        cashbac: 2
    }
};

// Basic config
Init.config = {
    carousel: {
        swipeable: true,
        draggable: true,
        showDots: true,
        responsive: {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        },
        ssr: false, // means to render carousel on server-side.
        infinite: true,
        autoPlay: false,
        autoPlaySpeed: 99999,
        keyBoardControl: true,
        customTransition: "all .5",
        transitionDuration: 500,
        removeArrowOnDeviceType: [
            // "tablet", 
            // "mobile", 
            // "web"
        ],
        deviceType: "web",
        partialVisible: false,
        centerMode: false,
        containerClass: "carousel-container",
        dotListClass: "custom-dot-list-style",
        itemClass: "carousel-item-padding-40-px"
    },
    customImageFilter: {
        none: [
            1, 0, 0, 0, 0,
            0, 1, 0, 0, 0,
            0, 0, 1, 0, 0,
            0, 0, 0, 1, 0,
        ],
        grayscale: [
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0, 0, 0, 1, 0,
        ]
    },
    imageFolder: "/assets/images/",
    logoFolder: "/assets/logo/",
    iconFolder: "/assets/icons/",
    sampleFolder: "/assets/sample/",
    isMobile: isMobile,
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.landackapp",
    appStoreLink: "https://apps.apple.com/id/app/landack/id6466232103",
    consultantGooglePlayLink: "https://play.google.com/store/apps/details?id=com.landacklawyerapp&hl=en_US",
    consultantAppStoreLink: "https://apps.apple.com/id/app/konsultan-hukum-landack/id6499170931"
}

Init.config.headerFolder = `${Init.config.imageFolder}header/`;
Init.config.collabFolder = `${Init.config.imageFolder}collab/`;
Init.config.clientFolder = `${Init.config.imageFolder}client/`;

Init.config.imageLogo = `${Init.config.logoFolder}logo.png`;
Init.config.imageLogoAlt = `${Init.config.logoFolder}logo-full.png`;
Init.config.imageLogoText = `${Init.config.logoFolder}logo-text.png`;

Init.config.sectionAssets = {
    header: `${Init.config.imageFolder}header-1.png`,
    whyUs: {
        simple: `${Init.config.imageFolder}why-us-simple.png`,
        affordable: `${Init.config.imageFolder}why-us-affordable-price.png`,
        fast: `${Init.config.imageFolder}why-us-fast.png`,
        accurate: `${Init.config.imageFolder}why-us-accurate.png`,
        prp: `${Init.config.imageFolder}why-us-prp.png`,
        professional: `${Init.config.imageFolder}pricing-ldd.png`
    },
    individualPricing: {
        consultation: `${Init.config.imageFolder}pricing-chat-consultation.png`,
        agreement: `${Init.config.imageFolder}pricing-agreement-docs.png`,
        nda: `${Init.config.imageFolder}pricing-nda.png`,
        somation: `${Init.config.imageFolder}pricing-somation.png`,
        somationAns: `${Init.config.imageFolder}pricing-somation-answer.png`,
        ldd: `${Init.config.imageFolder}pricing-ldd.png`,
        docReview: `${Init.config.imageFolder}pricing-document-review.png`,
        brandRegistration: `${Init.config.imageFolder}pricing-ldd.png`
    },
    corporatePricing: {
        bronze: `${Init.config.imageFolder}corporate-pricing-bronze.png`,
        silver: `${Init.config.imageFolder}corporate-pricing-silver.png`,
        gold: `${Init.config.imageFolder}corporate-pricing-gold.png`
    },
    businessEntity: {
        main: `${Init.config.imageFolder}business-entity.png`,
        confused: `${Init.config.imageFolder}confused.png`
    },
    b2bPackage: {
        main: `${Init.config.imageFolder}pricing-ldd.png`,
        subMain: `${Init.config.imageFolder}jualan-baso.png`,
    },
    collaborator: {
        unsri: `${Init.config.collabFolder}universitas-sriwijaya.png`,
        unsrifh: `${Init.config.collabFolder}bem-fh-universitas-sriwijaya.png`,
        binus: `${Init.config.collabFolder}binus-university.png`,
        binusbuslaw: `${Init.config.collabFolder}himpunan-mahasiswa-business-law-binus-university.png`,
        iccn: `${Init.config.collabFolder}iccn.png`,
        unnas: `${Init.config.collabFolder}universitas-nasional.png`,
        himakumunnas: `${Init.config.collabFolder}himakum-universitas-nasional.png`,
        uin: `${Init.config.collabFolder}universitas-islam-negeri-walisongo.png`,
        himakumuin: `${Init.config.collabFolder}himakum-universitas-islam-negeri-walisongo.png`,
        kalbis: `${Init.config.collabFolder}kalbis-student-development-and-alumni-center.png`,

        jakmall: `${Init.config.collabFolder}jakmall.png`,
        ksp: `${Init.config.collabFolder}komunitas-satu-persen.png`,
        studiokecil: `${Init.config.collabFolder}studio-kecil.png`,

        umm: `${ Init.config.collabFolder }universitas-muhammadiyah-malang.png`,
        ummfh: `${ Init.config.collabFolder }bem-fh-universitas-muhammadiyah-malang.png`,

        uskbemfh: `${ Init.config.collabFolder }bem-fh-universitas-syiah-kuala.png`,
        uskfh: `${ Init.config.collabFolder }fh-universitas-syiah-kuala.png`,

        ukdc: `${ Init.config.collabFolder }universitas-katolik-darma-cendika.png`,
        ukdcfh: `${ Init.config.collabFolder }bem-fh-universitas-katolik-darma-cendika.png`,
        ukdckabinetfh: `${ Init.config.collabFolder }kabinet-bem-fh-universitas-katolik-darma-cendika.png`
    },
    client: {
        propertiv: `${Init.config.clientFolder}propertiv.png`,
        reblock: `${Init.config.clientFolder}reblock.png`,
        tpm: `${Init.config.clientFolder}tpm.png`,
        tpml: `${Init.config.clientFolder}tpm-l.png`,
        listijo: `${Init.config.clientFolder}listijo.png`,
        gpa: `${Init.config.clientFolder}gpa.png`,
    }
}

Init.config.imageIcon = `${ Init.config.iconFolder }favicon.png`;
Init.config.quoteIcon = `${ Init.config.iconFolder }quote.png`;
Init.config.thumbup = `${ Init.config.iconFolder }thumbup.png`;
Init.config.appStoreIcon = `${ Init.config.iconFolder }app-store-icon.png`;
Init.config.googlePlayIcon = `${ Init.config.iconFolder }google-play-icon.png`;
Init.config.lowLiterationIcon = `${ Init.config.iconFolder }broken-mind.png`;
Init.config.priceClarityIcon = `${ Init.config.iconFolder }tired.png`;

Init.config.headerBackground = `${ Init.config.imageFolder }scenery.jpg`;
Init.config.androidAndIos = `${ Init.config.imageFolder }android-and-ios.png`;
Init.config.confused = `${ Init.config.imageFolder }landack-confused.png`;

Init.config.lawyer = `${ Init.config.imageFolder }landack-advokat.png`;
Init.config.salute = `${ Init.config.imageFolder }landack-hormat.png`;
Init.config.excited = Init.config.sectionAssets.whyUs.affordable;
Init.config.yes = `${ Init.config.imageFolder }landack-yes.png`;
Init.config.accountant = `${ Init.config.imageFolder }landack-accountant.png`;

// Extensions
library.add(fab, fas, far);
Init.FontAwesomeIcon = FontAwesomeIcon;


export default Init;