import React from 'react';
import { Link } from 'react-router-dom';

import Init from '../../../Init/Init';

import './BusinessEntity.scss';
import { businessEntityLink } from '../../../Util/Util';

const { sectionAssets } = Init.config;

const BusinessEntity = (props) => {
    return (
        <div className='business-entity'>
            {/* 
                2 parts:
                1. Title
                2. Button to redirect to another page
            */}
            <div className="business-entity-assets">
                <img src={sectionAssets.businessEntity.main} alt="" />
            </div>
            <div className="business-entity-header">
                <h2>
                    Butuh jasa pendirian badan usaha? Landack punya Starter Kit!
                </h2>
            </div>
            <div className="business-entity-button-container">
                <a href={ businessEntityLink('', false) } className='btn-business-entity' target="_blank">
                    Cek disini!
                </a>
            </div>
        </div>
    );
}

export default BusinessEntity;